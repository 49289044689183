<template>
  <section id="login-page">
    <div class="login-card">
      <el-form class="login-form">
        <h3 class="login-form-title">
          {{ $t("login.title") }}
        </h3>
        <p class="login-form-subtitle">
          {{ $t("login.welcome") }}
        </p>

        <p
          v-if="errorMessage"
          class="login-form-error"
        >
          {{ errorMessage }}
        </p>
        <el-form-item
          prop="email"
          class="login-form-item"
        >
          <label
            class="login-form-item-label"
            for="email"
          >
            {{ $t("login.email") }}*
          </label>
          <el-input
            v-model="email"
            name="email"
            type="email"
            class="input-field"
          />
        </el-form-item>

        <el-form-item
          prop="email"
          class="login-form-item"
        >
          <label
            class="login-form-item-label"
            for="password"
          >
            {{ $t("login.password") }}*
          </label>
          <el-input
            v-model="password"
            name="password"
            type="password"
            class="input-field"
          />
        </el-form-item>

        <div class="flex">
          <el-form-item
            prop="rememberMe"
            class="login-form-checkbox"
          >
            <el-checkbox v-model="rememberMe">
              <label class="login-form-checkbox-label">
                {{ $t("login.remember") }}
              </label>
            </el-checkbox>
          </el-form-item>
          <p class="login-form-forgot-message">
            {{ $t("login.forgetMessage") }}
          </p>
        </div>

        <el-button
          :loading="formSubmitting"
          class="button login-form-submit"
          :disabled="!email || !password"
          @click="onSubmit"
        >
          {{ $t("button.submit") }}
        </el-button>
      </el-form>

      <img
        :src="require('@/assets/images/background/login-banner.svg')"
        class="login-banner"
      >
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'Login',
  components: {},
  data: () => ({
    email: '',
    password: '',
    rememberMe: false,
    formSubmitting: false,
    errorMessage: '',
  }),
  created() {
    const { password, username } = JSON.parse(localStorage.getItem('credential') || '{}');

    this.email = username || '';
    this.password = password || '';
    this.rememberMe = !!username;
  },
  methods: {
    ...mapActions(['syncAuth']),
    async onSubmit() {
      this.formSubmitting = true;
      try {
        const user = { password: this.password, username: this.email };
        await this.$api.auth.loginUser(user);

        if (this.rememberMe) {
          localStorage.setItem('credential', JSON.stringify(user));
        } else {
          localStorage.removeItem('credential');
        }

        await this.syncAuth();
        this.$router.push('/services/mt');
      } catch ({ response }) {
        this.errorMessage = response?.data?.detail || 'Something went wrong. Please try again!';
      } finally {
        this.formSubmitting = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#login-page {
  background: url("~@/assets/images/background/contact-cloud.svg") top left
      no-repeat,
    url("~@/assets/images/background/contact-cloud-2.svg") top right no-repeat,
    $background-secondary;
  padding: 0.32rem 0.86rem 0.6rem;

  .login {
    &-card {
      background-color: $color_white;
      padding: 0.25rem;
      box-shadow: 0px 4px 4px rgba(156, 152, 181, 0.24);
      border-radius: 22px;
      display: flex;
      max-width: 13rem;
      margin: auto;
    }

    &-form {
      margin-left: auto;
      margin-right: auto;
      min-width: 30%;
      width: 4.2rem;
      max-width: 50%;
      display: flex;
      flex-direction: column;

      &-title {
        margin-top: auto;
        color: $text-primary;
        font-weight: 800;
        font-size: 0.36rem;
        line-height: 0.49rem;
        margin-bottom: 0.1rem;
        text-transform: capitalize;
      }

      &-subtitle {
        color: $text-tertiary;
        font-weight: 600;
        font-size: 0.16rem;
        line-height: 0.26rem;
        margin-bottom: .16rem;
      }

      &-item {
        position: relative;
        border: 1px solid rgba(187, 187, 190, 0.49);
        border-radius: 6px;
        margin-bottom: .36rem;

        &-label {
          position: absolute;
          z-index: 1;
          top: 0.12rem;
          left: 0.2rem;
          font-size: 0.12rem;
          line-height: 0.15rem;
          opacity: 0.5;
          text-transform: capitalize;
          color: rgba(58, 57, 85, 0.5);
        }

        /deep/ .el-select {
          width: 100%;
        }

        /deep/ .el-input__inner {
          @include input-field;
          height: 0.64rem;
          padding: 0.32rem 0.2rem 0.12rem;
        }

        /deep/ .el-form-item {
          margin-bottom: 0.1rem;
        }
      }

      &-checkbox {
        margin-bottom: 0.1rem;

        &-label {
          font-weight: 600;
          font-size: 0.12rem;
          line-height: 0.15rem;
          color: black;
          text-transform: capitalize;
        }
      }

      &-submit {
        width: 100%;
        border: none;
        color: white;
        background: $background-primary;
        padding: 0.19rem 0;
        line-height: 0.22rem;
        margin-bottom: auto;

        &:disabled {
          opacity: 0.5;
        }
      }

      &-forgot {
        &-message {
          white-space: pre-wrap;
          text-align: right;
          color: $text-tertiary;
          font-size: 0.1rem;
          line-height: 0.13rem;
          font-weight: 500;
          margin-left: auto;
        }
      }

      &-error {
        margin-bottom: .1rem;
        font-weight: 400;
        font-size: .1rem;
        line-height: .13rem;
        color: #E00824;
      }
    }

    &-banner {
      margin-left: auto;
      padding-left: 0.25rem;
    }
  }
}
</style>
